import { Typography } from '@naamdeo/shadcn-ui/components/typography';
import { LoaderFunctionArgs, MetaFunction } from '@remix-run/cloudflare';
import { Await, Link, useLoaderData } from '@remix-run/react';
import { Suspense } from 'react';
import { CourseCard, CourseCardSkeleton } from '~/components/course-card';
import { ContentSection } from '~/components/page-layout/content-section';
import { getSeoMeta } from '~/lib/seo';
import { wrapPromise } from '~/lib/ts-util';

export const meta: MetaFunction = (args) => {
  return getSeoMeta(
    {
      title: 'Discover',
    },
    args
  );
};

export const loader = (args: LoaderFunctionArgs) => {
  const availableCourses = wrapPromise(args.context.cloudflare.env.PUBLIC_DATA_SERVICE.listAvailableCourses());
  return {
    availableCourses,
  };
};

export default function Index() {
  const { availableCourses: availableCoursesPromise } = useLoaderData<typeof loader>();
  return (
    <>
      <ContentSection>
        <Typography variant="h1" className="mb-12 text-primary">
          Course Catalog
        </Typography>
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          <Suspense
            fallback={
              <>
                <CourseCardSkeleton />
                <CourseCardSkeleton />
                <CourseCardSkeleton />
              </>
            }
          >
            <Await resolve={availableCoursesPromise}>
              {(availableCourses) =>
                availableCourses.map((course) => (
                  <Link key={course.id} to={`/course/${course.machineSlug}`}>
                    <CourseCard
                      title={course.title}
                      thumbnail={`https://public-assets.naamdeo.org/videos/${course.machineSlug}/thumbnail.jpg`}
                      slug={course.machineSlug}
                      chapters={course.lessonCount}
                      durationSeconds={course.durationSeconds}
                    />
                  </Link>
                ))
              }
            </Await>
          </Suspense>
        </div>
      </ContentSection>
    </>
  );
}
